<template>
  <v-dialog
    v-if="open"
    :value="open"
    :retain-focus="false"
    transition="dialog-bottom-transition"
    persistent
    fullscreen
  >
    <v-card>
      <v-container>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-card-title>
            <img
              :src="
                !theme
                  ? require('@/assets/logo/default.svg')
                  : require('@/assets/logo/white.svg')
              "
              alt="Logo Biobe"
            />
            <v-spacer />
            <h3 class="font-weight-regular">Termos e Condições de Uso</h3>
          </v-card-title>
          <v-card-text class="pt-5">
            <div class="accept-terms">
              <div
                id="scroll-target"
                name="scroll-target"
                class="overflow-y-auto"
                :style="'max-height: 350px'"
              >
                <div
                  class="content-markdown"
                  v-scroll:#scroll-target="onScroll"
                  v-html="markdown"
                />
              </div>
            </div>
            <v-checkbox
              id="acceptedPrivacyTerm"
              name="acceptedPrivacyTerm"
              color="primary"
              label="Li e concordo com os termos de uso e privacidade"
              required
              :rules="[required]"
              v-model="acceptedPrivacyTerm"
            />
          </v-card-text>
          <v-card-actions>
            <BaseButton color="secondary" title="Não aceito" @click="logoff" />
            <v-spacer />
            <BaseButton
              color="primary"
              title="Aceito"
              :disabled="btnDisabled"
              @click="execute"
            />
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { logoutMixin } from '@/mixins/logout';
import { Remarkable } from 'remarkable';
import { AccountService } from '@/services/api/account';

export default {
  mixins: [rulesMixin, logoutMixin],

  data: () => ({
    valid: true,
    btnDisabled: true,
    acceptedPrivacyTerm: false,
    position: 0,
    textHeight: 0,
    markdown: null
  }),

  computed: {
    open() {
      const result = this.$store.getters['authentication/getAcceptTerm'];

      if (result) {
        this.search();
      }

      return result;
    },

    theme() {
      return this.$vuetify.theme.isDark;
    }
  },

  methods: {
    async search() {
      await this.renderMarkdown();

      const text = document.querySelectorAll(
        '.accept-terms #scroll-target > div'
      );

      if (text.length) {
        this.textHeight = text[0].offsetHeight - 350;
      }
    },

    async renderMarkdown() {
      try {
        const accountService = new AccountService();
        const { data } = await accountService.getMarkdown();

        if (data) {
          const md = new Remarkable({
            html: false,
            xhtmlOut: true,
            breaks: true,
            langPrefix: 'language-',
            linkTarget: '_blank',
            typographer: true,
            quotes: '“”‘’'
          });

          this.markdown = md.render(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    onScroll: function (e) {
      this.position = e.target.scrollTop;
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const accountService = new AccountService();
        const { status } = await accountService.acceptPrivacyTerm({
          acceptedPrivacyTerm: true
        });

        if (status === 204) {
          this.$store.commit('authentication/acceptPrivacyTerm');
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    position(newValue) {
      if (newValue >= this.textHeight) {
        this.btnDisabled = false;
      }
    }
  }
};
</script>

<style lang="scss">
.content-markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    padding-bottom: 5px;
    margin: 15px 0;
  }

  p {
    strong {
      color: var(--v-primary-base);
    }
  }

  ul {
    margin-bottom: 10px;

    li {
      list-style-type: disc;
      padding-bottom: 5px;

      p {
        margin: 0;
      }
    }
  }
}
</style>
